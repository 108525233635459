import React from "react";
// import NavLink from "./NavLink";
import PrimaryButton from "./buttons/PrimaryButton";
import { NavLink } from "react-router";
import openCalendlyPopup from "../data/calendly";

export default function NavMenu({ type, isActive }) {
    const types = {
        mobile: {
            container:
                "fixed top-0 left-0 w-full h-full pt-24 opacity-0 bg-black-500 text-white-200 -translate-y-full transition-all duration-500 [&.active]:opacity-100 [&.active]:translate-y-0",
            link: "block text-center p-4 hover:bg-white-200 hover:text-charcoal-800 duration-300",
        },
        desktop: {
            container: "hidden md:flex gap-x-6 items-center",
            link: "hover:text-charcoal-100 duration-300",
        },
    };

    return (
        <div
            className={
                `${isActive === true ? "active " : ""}` +
                types[type]["container"]
            }
        >
            <NavLink to={"/about"} className={types[type]["link"]}>
                My Story
            </NavLink>
            <NavLink to={"/work"} className={types[type]["link"]}>
                Work
            </NavLink>
            {type === "mobile" ? (
                <NavLink text={"Get In Touch"} url={"#"} className={types[type]["link"]} />
            ) : (
                <a href="#">
                    <PrimaryButton onClick={openCalendlyPopup}>Get In Touch</PrimaryButton>
                </a>
            )}
        </div>
    );
}
