import React, { useEffect, useRef, useState } from "react";
import NavMenu from "./NavMenu";
import Container from "./Container";
import { Link } from "react-router";

export default function Navbar() {
    const [active, setActive] = useState(false);
    const nav = useRef();

    function toggleNav() {
        setActive(!active);
    }

    useEffect(() => {
        const el = nav.current;
        const observer = new IntersectionObserver(
            ([e]) =>
                e.target.classList.toggle("stuck", e.intersectionRatio < 1),
            { threshold: [1] }
        );

        observer.observe(el);
        console.log("observing");
    }, []);

    return (
        <header
            ref={nav}
            className="bg-black-500 text-white-100 sticky top-[-1px] z-10 [&.stuck]:bg-transparent [&.stuck]:rounded-lg [&.stuck]:p-2 md:[&.stuck]:p-5 group duration-300"
        >
            <Container>
                <nav className="relative flex items-center justify-between z-20 bg-black-500 py-4 group-[.stuck]:px-4 group-[.stuck]:rounded-lg group-[.stuck]:drop-shadow-2xl duration-300">
                    <Link
                        to={"/"}
                        className="uppercase text-2xl font-bold tracking-widest"
                    >
                        Hassan Aziz
                    </Link>

                    <NavMenu type={"desktop"} />

                    <button
                        onClick={toggleNav}
                        className="block md:hidden"
                        aria-label="hamburger icon"
                    >
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 15 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M1.5 3C1.22386 3 1 3.22386 1 3.5C1 3.77614 1.22386 4 1.5 4H13.5C13.7761 4 14 3.77614 14 3.5C14 3.22386 13.7761 3 13.5 3H1.5ZM1 7.5C1 7.22386 1.22386 7 1.5 7H13.5C13.7761 7 14 7.22386 14 7.5C14 7.77614 13.7761 8 13.5 8H1.5C1.22386 8 1 7.77614 1 7.5ZM1 11.5C1 11.2239 1.22386 11 1.5 11H13.5C13.7761 11 14 11.2239 14 11.5C14 11.7761 13.7761 12 13.5 12H1.5C1.22386 12 1 11.7761 1 11.5Z"
                                fill="currentColor"
                            />
                        </svg>
                    </button>
                </nav>
            </Container>

            <div className="z-10">
                <NavMenu type={"mobile"} isActive={active} />
            </div>
        </header>
    );
}
